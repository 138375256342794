import React from "react"
import Img from "gatsby-image"

import { Box, Container, Typography, Button, Grid, Stack } from "@mui/material"
import ArrowRightIcon from "@mui/icons-material/ArrowRight"

const Head = ({ data }: any) => {
  return (
    <Box
      display="flex"
      flexDirection="row"
      flexWrap="wrap"
      width="100%"
      justifyContent="center"
      alignItems="center"
      height={"85vh"}
    >
      <Container maxWidth="xl">
        <Grid container pt={8}>
          <Grid
            item
            xs={12}
            md={6}
            display="flex"
            justifyContent="flex-start"
            alignItems="center"
          >
            <Box>
              <Typography
                color="primary"
                variant="h1"
                sx={{
                  fontSize: "40px",
                  letterSpacing: 1,
                  fontFamily:
                    "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif",
                }}
              >
                Leadership Hiring Specialists
              </Typography>
              <Typography
                variant="h2"
                sx={{
                  fontSize: "36px",
                  fontWeight: "500",
                  color: "#8F8F8F",
                  marginTop: 1,
                  fontFamily:
                    "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif",
                }}
              >
                The Right Role,
              </Typography>
              <Typography
                variant="h2"
                sx={{
                  fontSize: "36px",
                  fontWeight: "500",
                  color: "#8F8F8F",
                  marginTop: 1,
                  fontFamily:
                    "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif",
                }}
              >
                The Right Person,
              </Typography>
              <Typography
                variant="h2"
                sx={{
                  fontSize: "36px",
                  fontWeight: "500",
                  color: "#8F8F8F",
                  marginTop: 1,
                  fontFamily:
                    "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif",
                }}
              >
                The Right Fit
              </Typography>

              <Stack
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                gap={2}
                mt={3}
              >
                <Button
                  variant="contained"
                  size="large"
                  color="primary"
                  sx={{ fontSize: "14px" }}
                >
                  HIRE EXPERT TALENT
                </Button>
                <Button
                  href="/"
                  color="primary"
                  sx={{
                    textTransform: "none",
                    textDecoration: "underline",
                    fontSize: "14px",
                    "&:hover": {
                      backgroundColor: "inherit",
                      textDecoration: "underline",
                    },
                    "& .MuiButton-endIcon": {
                      marginLeft: "-4px",
                    },
                  }}
                  endIcon={<ArrowRightIcon fontSize="inherit" />}
                >
                  Explores jobs
                </Button>
              </Stack>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box>
              <Img fluid={data.file.childImageSharp.fluid} />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}

export default Head
