import React from "react"
import {
  Grid,
  Typography,
  Stack,
  styled,
  Container,
  Button,
} from "@mui/material"
import PeopleOutlineOutlinedIcon from "@mui/icons-material/PeopleOutlineOutlined"
import PersonSearchOutlinedIcon from "@mui/icons-material/PersonSearchOutlined"
import ContentPasteSearchOutlinedIcon from "@mui/icons-material/ContentPasteSearchOutlined"

const ActionBox = styled(Stack)({
  border: "1px solid rgba(74, 58, 118, .5)",
  borderRadius: 4,
  padding: 30,
  minHeight: 220,
  flex: 1,
  height: "100%",
})

const QuickActions = () => {
  return (
    <Grid container justifyContent="center" mb={4}>
      {/* <Grid item xs={12}>
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          gap={2}
        >
          <Typography
            variant="subtitle2"
            textTransform="uppercase"
            fontWeight="bold"
          >
            Why Savanna HR
          </Typography>
          <Typography variant="h3" color="primary">
            New way to get a job
          </Typography>
          <Typography variant="body2" textAlign="center">
            Upon gaining entry to the JobBoard platform, your initial task{" "}
            <br />
            involves inputting your initial team positions or roles.
          </Typography>
        </Stack>
      </Grid> */}
      <Container maxWidth="xl" sx={{ maxWidth: "1280px", margin: "0 auto" }}>
        <div id="w-node-b491b034-4662-c0bf-5d56-a17ad5cfee6c-d5cfee6b">
          <div className="text-regular-all-caps margin-bottom margin-small slide-to-top-0-1s">
            Why Savanna HR
          </div>
          <div className="custom-divider margin-bottom margin-small full-width-animation">
            <div className="divider-detail _24px-wide-animation"></div>
          </div>
          <h2 className="slide-to-top-0-2s">New way to get a job</h2>
        </div>
      </Container>

      <Grid container maxWidth="xl" spacing={3} my={2}>
        <Grid item xs={4}>
          <ActionBox
            direction="column"
            gap={3}
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <PersonSearchOutlinedIcon
              color="primary"
              sx={{ ml: 1 }}
              fontSize="large"
            />
            <Typography ml={1}>Looking to hire?</Typography>
            <Typography variant="body2" ml={1}>
              Speak to one of our experienced consultants about your recruitment
              needs.
            </Typography>
            <Button variant="outlined" sx={{ width: "200px" }}>
              Request a call back
            </Button>
          </ActionBox>
        </Grid>
        <Grid item xs={4}>
          <ActionBox
            direction="column"
            gap={3}
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <PeopleOutlineOutlinedIcon
              color="primary"
              sx={{ ml: 1 }}
              fontSize="large"
            />
            <Typography ml={1}>Join Our team</Typography>
            <Typography variant="body2" ml={1}>
              Please tell us more about your experience with Savanna HR.
            </Typography>
            <Button variant="outlined" sx={{ width: "200px" }}>
              Join Us
            </Button>
          </ActionBox>
        </Grid>
        <Grid item xs={4}>
          <ActionBox
            direction="column"
            gap={3}
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <ContentPasteSearchOutlinedIcon
              color="primary"
              sx={{ ml: 1 }}
              fontSize="large"
            />
            <Typography ml={1}>Start your job search</Typography>
            <Typography variant="body2" ml={1}>
              Get started with your job search today and take the next step in
              your career.
            </Typography>
            <Button variant="outlined" sx={{ width: "200px" }}>
              Search for jobs
            </Button>
          </ActionBox>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default QuickActions
