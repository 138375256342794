import React from "react"
import { Box, Stack, Button } from "@mui/material"

import "./webflow.css"

const LatestBlogs = () => {
  return (
    <section className="padding-section-medium side-padding margin-horizontal margin-large">
      <Box
        sx={{
          width: "100%",
          maxWidth: "1200px",
          margin: "0 auto",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "flex-end",
          paddingLeft: 0,
          paddingRight: 0,
          marginBottom: 4,
        }}
      >
        <div style={{ width: "50%" }}>
          <div className="text-regular-all-caps margin-bottom margin-small slide-to-top-0-1s">
            Blogs
          </div>
          <div className="custom-divider margin-bottom margin-small full-width-animation">
            <div className="divider-detail _24px-wide-animation"></div>
          </div>
          <h2 className="slide-to-top-0-2s">Latest Blog Posts</h2>
        </div>
        <Stack
          direction="row"
          gap={1}
          justifyContent="flex-end"
          alignItems="center"
        >
          <Button variant="outlined" size="large">
            View All Articles
          </Button>
        </Stack>
      </Box>
      <div className="w-layout-blockcontainer default-width w-container">
        <div>
          <div
            className="slide-to-top-0-1s w-dyn-list"
            style={{
              transform:
                "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
              transformStyle: "preserve-3d",
              opacity: 1,
            }}
          >
            <div role="list" className="blog-cms-grid w-dyn-items">
              <div
                role="listitem"
                className="collection-item slide-to-top-0-1s w-dyn-item"
                style={{
                  transform:
                    "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                  transformStyle: "preserve-3d",
                  opacity: 1,
                }}
              >
                <a
                  href="/post/securing-your-financial-future-through-retirement"
                  className="thumbnail-link-image w-inline-block"
                >
                  <img
                    className="margin-bottom margin-small"
                    width="Auto"
                    style={{
                      objectFit: "cover",
                      height: "250px",
                    }}
                    srcSet="https://savannahr.com/blog/content/images/size/w400/2024/07/Screenshot-2024-07-09-at-10.26.10-PM--1-.png 400w,
                            https://savannahr.com/blog/content/images/size/w750/2024/07/Screenshot-2024-07-09-at-10.26.10-PM--1-.png 750w,
                            https://savannahr.com/blog/content/images/size/w960/2024/07/Screenshot-2024-07-09-at-10.26.10-PM--1-.png 960w,
                            https://savannahr.com/blog/content/images/size/w1140/2024/07/Screenshot-2024-07-09-at-10.26.10-PM--1-.png 1140w,
                            https://savannahr.com/blog/content/images/size/w1920/2024/07/Screenshot-2024-07-09-at-10.26.10-PM--1-.png 1920w"
                    sizes="(max-width: 479px) 90vw, (max-width: 767px) 94vw, (max-width: 991px) 95vw, 27vw"
                    src="https://savannahr.com/blog/content/images/size/w960/2024/07/Screenshot-2024-07-09-at-10.26.10-PM--1-.png"
                    alt="Questions to Ask Hiring Managers During a Job Interview"
                    loading="lazy"
                  />
                  {/* <img
                    src="https://cdn.prod.website-files.com/661d4839d9db6424c7cc0c1a/661f805abe29f59f7a48ac31_blog-image-2.jpg"
                    loading="lazy"
                    width="Auto"
                    alt=""
                    sizes="(max-width: 479px) 90vw, (max-width: 767px) 94vw, (max-width: 991px) 95vw, 27vw"
                    srcSet="https://cdn.prod.website-files.com/661d4839d9db6424c7cc0c1a/661f805abe29f59f7a48ac31_blog-image-2-p-500.jpg 500w, https://cdn.prod.website-files.com/661d4839d9db6424c7cc0c1a/661f805abe29f59f7a48ac31_blog-image-2-p-800.jpg 800w, https://cdn.prod.website-files.com/661d4839d9db6424c7cc0c1a/661f805abe29f59f7a48ac31_blog-image-2-p-1080.jpg 1080w, https://cdn.prod.website-files.com/661d4839d9db6424c7cc0c1a/661f805abe29f59f7a48ac31_blog-image-2.jpg 1568w"
                    className="margin-bottom margin-small"
                  /> */}
                </a>
                <div className="text-tiny-all-caps text-color-dark margin-bottom margin-xsmall">
                  Hiring Manager
                </div>
                <a
                  href="/post/securing-your-financial-future-through-retirement"
                  className="heading-link w-inline-block"
                >
                  <h3
                    aria-label="blog post link"
                    className="heading-style-h5 darkest-hover"
                  >
                    Questions to Ask Hiring Managers During a Job Interview
                  </h3>
                </a>
                <div className="author-line margin-top margin-xxsmall">
                  <img
                    src="https://media.licdn.com/dms/image/D5635AQH8OLTqTX3wuA/profile-framedphoto-shrink_200_200/0/1721631829515?e=1723618800&v=beta&t=YQwASN4FjP2KD4W5G4ebDWcrCNRRgaf_-6fS6I9nGI0"
                    loading="lazy"
                    alt=""
                    className="blog-author-small inline-block"
                  />
                  <div className="text-color-darker margin-left margin-xxsmall">
                    Swati Sinha
                  </div>
                </div>
              </div>
              <div
                role="listitem"
                className="collection-item slide-to-top-0-1s w-dyn-item"
                style={{
                  transform:
                    "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                  transformStyle: "preserve-3d",
                  opacity: 1,
                }}
              >
                <a
                  href="/post/the-smart-way-to-insure-your-financial-assets"
                  className="thumbnail-link-image w-inline-block"
                >
                  <img
                    className="margin-bottom margin-small"
                    style={{
                      objectFit: "cover",
                      height: "250px",
                    }}
                    width="auto"
                    srcSet="https://savannahr.com/blog/content/images/size/w400/2024/07/12-Interview-Questions-to-Ask-Remote-Workers-690x340.jpg 400w,
                    https://savannahr.com/blog/content/images/size/w750/2024/07/12-Interview-Questions-to-Ask-Remote-Workers-690x340.jpg 750w,
                    https://savannahr.com/blog/content/images/size/w960/2024/07/12-Interview-Questions-to-Ask-Remote-Workers-690x340.jpg 960w,
                    https://savannahr.com/blog/content/images/size/w1140/2024/07/12-Interview-Questions-to-Ask-Remote-Workers-690x340.jpg 1140w,
                    https://savannahr.com/blog/content/images/size/w1920/2024/07/12-Interview-Questions-to-Ask-Remote-Workers-690x340.jpg 1920w"
                    sizes="(max-width: 479px) 90vw, (max-width: 767px) 94vw, (max-width: 991px) 95vw, 27vw"
                    src="https://savannahr.com/blog/content/images/size/w960/2024/07/12-Interview-Questions-to-Ask-Remote-Workers-690x340.jpg"
                    alt="How to Gracefully Resign from your Job"
                    loading="lazy"
                  ></img>
                  {/* <img
                    className="margin-bottom margin-small"
                    srcSet="https://savannahr.com/blog/content/images/size/w400/2024/07/12-Interview-Questions-to-Ask-Remote-Workers-690x340.jpg 400w,
                            https://savannahr.com/blog/content/images/size/w750/2024/07/12-Interview-Questions-to-Ask-Remote-Workers-690x340.jpg 750w,
                            https://savannahr.com/blog/content/images/size/w960/2024/07/12-Interview-Questions-to-Ask-Remote-Workers-690x340.jpg 960w,
                            https://savannahr.com/blog/content/images/size/w1140/2024/07/12-Interview-Questions-to-Ask-Remote-Workers-690x340.jpg 1140w,
                            https://savannahr.com/blog/content/images/size/w1920/2024/07/12-Interview-Questions-to-Ask-Remote-Workers-690x340.jpg 1920w"
                    sizes="(max-width: 479px) 90vw, (max-width: 767px) 94vw, (max-width: 991px) 95vw, 27vw"
                    src="https://savannahr.com/blog/content/images/size/w960/2024/07/12-Interview-Questions-to-Ask-Remote-Workers-690x340.jpg"
                    alt="10 Common Job Interview Questions and Sample Answers"
                    loading="lazy"
                  /> */}
                  {/* <img
                    src="https://cdn.prod.website-files.com/661d4839d9db6424c7cc0c1a/661f800f31df14189e1e54d0_blog-image-9.jpg"
                    loading="lazy"
                    width="Auto"
                    alt=""
                    sizes="(max-width: 479px) 90vw, (max-width: 767px) 94vw, (max-width: 991px) 95vw, 27vw"
                    srcSet="https://cdn.prod.website-files.com/661d4839d9db6424c7cc0c1a/661f800f31df14189e1e54d0_blog-image-9-p-500.jpg 500w, https://cdn.prod.website-files.com/661d4839d9db6424c7cc0c1a/661f800f31df14189e1e54d0_blog-image-9-p-800.jpg 800w, https://cdn.prod.website-files.com/661d4839d9db6424c7cc0c1a/661f800f31df14189e1e54d0_blog-image-9-p-1080.jpg 1080w, https://cdn.prod.website-files.com/661d4839d9db6424c7cc0c1a/661f800f31df14189e1e54d0_blog-image-9.jpg 1568w"
                    className="margin-bottom margin-small"
                  /> */}
                </a>
                <div className="text-tiny-all-caps text-color-dark margin-bottom margin-xsmall">
                  Job Interview
                </div>
                <a
                  href="/post/the-smart-way-to-insure-your-financial-assets"
                  className="heading-link w-inline-block"
                >
                  <h3
                    aria-label="blog post link"
                    className="heading-style-h5 darkest-hover"
                  >
                    10 Common Job Interview Questions and Sample Answers
                  </h3>
                </a>
                <div className="author-line margin-top margin-xxsmall">
                  <img
                    src="https://media.licdn.com/dms/image/D5635AQH8OLTqTX3wuA/profile-framedphoto-shrink_200_200/0/1721631829515?e=1723618800&v=beta&t=YQwASN4FjP2KD4W5G4ebDWcrCNRRgaf_-6fS6I9nGI0"
                    loading="lazy"
                    alt=""
                    className="blog-author-small inline-block"
                  />
                  <div className="text-color-darker margin-left margin-xxsmall">
                    Swati Sinha
                  </div>
                </div>
              </div>
              <div
                role="listitem"
                className="collection-item slide-to-top-0-1s w-dyn-item"
                style={{
                  transform:
                    "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                  transformStyle: "preserve-3d",
                  opacity: 1,
                }}
              >
                <a
                  href="/post/smart-estate-planning-for-financial-beginners"
                  className="thumbnail-link-image w-inline-block"
                >
                  <img
                    className="margin-bottom margin-small"
                    srcSet="https://savannahr.com/blog/content/images/size/w400/2024/05/istockphoto-1193929682-1024x1024.jpg 400w,
                            https://savannahr.com/blog/content/images/size/w750/2024/05/istockphoto-1193929682-1024x1024.jpg 750w,
                            https://savannahr.com/blog/content/images/size/w960/2024/05/istockphoto-1193929682-1024x1024.jpg 960w,
                            https://savannahr.com/blog/content/images/size/w1140/2024/05/istockphoto-1193929682-1024x1024.jpg 1140w,
                            https://savannahr.com/blog/content/images/size/w1920/2024/05/istockphoto-1193929682-1024x1024.jpg 1920w"
                    sizes="(max-width: 479px) 90vw, (max-width: 767px) 94vw, (max-width: 991px) 95vw, 27vw"
                    src="https://savannahr.com/blog/content/images/size/w960/2024/05/istockphoto-1193929682-1024x1024.jpg"
                    alt="How to Gracefully Resign from your Job"
                    style={{
                      objectFit: "cover",
                      height: "250px",
                    }}
                    loading="lazy"
                  ></img>
                  {/* <img
                    src="https://cdn.prod.website-files.com/661d4839d9db6424c7cc0c1a/661f7fb938b63e1acbc26976_blog-image-8.jpg"
                    loading="lazy"
                    width="Auto"
                    alt=""
                    sizes="(max-width: 479px) 90vw, (max-width: 767px) 94vw, (max-width: 991px) 95vw, 27vw"
                    srcSet="https://cdn.prod.website-files.com/661d4839d9db6424c7cc0c1a/661f7fb938b63e1acbc26976_blog-image-8-p-500.jpg 500w, https://cdn.prod.website-files.com/661d4839d9db6424c7cc0c1a/661f7fb938b63e1acbc26976_blog-image-8-p-800.jpg 800w, https://cdn.prod.website-files.com/661d4839d9db6424c7cc0c1a/661f7fb938b63e1acbc26976_blog-image-8-p-1080.jpg 1080w, https://cdn.prod.website-files.com/661d4839d9db6424c7cc0c1a/661f7fb938b63e1acbc26976_blog-image-8.jpg 1568w"
                    className="margin-bottom margin-small"
                  /> */}
                </a>
                <div className="text-tiny-all-caps text-color-dark margin-bottom margin-xsmall">
                  Recruiter Management
                </div>
                <a
                  href="/post/smart-estate-planning-for-financial-beginners"
                  className="heading-link w-inline-block"
                >
                  <h3
                    aria-label="blog post link"
                    className="heading-style-h5 darkest-hover"
                  >
                    How to Gracefully Resign from your Job
                  </h3>
                </a>
                <div className="author-line margin-top margin-xxsmall">
                  <img
                    src="https://media.licdn.com/dms/image/D5635AQH8OLTqTX3wuA/profile-framedphoto-shrink_200_200/0/1721631829515?e=1723618800&v=beta&t=YQwASN4FjP2KD4W5G4ebDWcrCNRRgaf_-6fS6I9nGI0"
                    loading="lazy"
                    alt=""
                    className="blog-author-small inline-block"
                  />
                  <div className="text-color-darker margin-left margin-xxsmall">
                    Swati Sinha
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="w-layout-blockcontainer default-width w-container"
        style={{ marginTop: "3em" }}
      >
        <div>
          <div
            className="slide-to-top-0-1s w-dyn-list"
            style={{
              transform:
                "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
              transformStyle: "preserve-3d",
              opacity: 1,
            }}
          >
            <div role="list" className="blog-cms-grid w-dyn-items">
              <div
                role="listitem"
                className="collection-item slide-to-top-0-1s w-dyn-item"
                style={{
                  transform:
                    "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                  transformStyle: "preserve-3d",
                  opacity: 1,
                }}
              >
                <a
                  href="/post/securing-your-financial-future-through-retirement"
                  className="thumbnail-link-image w-inline-block"
                >
                  <img
                    className="margin-bottom margin-small"
                    width="Auto"
                    style={{
                      objectFit: "cover",
                      height: "250px",
                    }}
                    srcSet="https://savannahr.com/blog/content/images/size/w400/2024/05/social-media.jpeg 400w,
                            https://savannahr.com/blog/content/images/size/w750/2024/05/social-media.jpeg 750w,
                            https://savannahr.com/blog/content/images/size/w960/2024/05/social-media.jpeg 960w,
                            https://savannahr.com/blog/content/images/size/w1140/2024/05/social-media.jpeg 1140w,
                            https://savannahr.com/blog/content/images/size/w1920/2024/05/social-media.jpeg 1920w"
                    sizes="(min-width: 960px) 960px, 92vw"
                    src="https://savannahr.com/blog/content/images/size/w960/2024/05/social-media.jpeg"
                    alt="The Role of Social-Media in Your Job Search"
                    loading="lazy"
                  ></img>
                  {/* <img
                    className="margin-bottom margin-small"
                    width="Auto"
                    style={{
                      objectFit: "cover",
                      height: "220px",
                    }}
                    srcSet="https://savannahr.com/blog/content/images/size/w400/2024/07/Screenshot-2024-07-09-at-10.26.10-PM--1-.png 400w,
                            https://savannahr.com/blog/content/images/size/w750/2024/07/Screenshot-2024-07-09-at-10.26.10-PM--1-.png 750w,
                            https://savannahr.com/blog/content/images/size/w960/2024/07/Screenshot-2024-07-09-at-10.26.10-PM--1-.png 960w,
                            https://savannahr.com/blog/content/images/size/w1140/2024/07/Screenshot-2024-07-09-at-10.26.10-PM--1-.png 1140w,
                            https://savannahr.com/blog/content/images/size/w1920/2024/07/Screenshot-2024-07-09-at-10.26.10-PM--1-.png 1920w"
                    sizes="(max-width: 479px) 90vw, (max-width: 767px) 94vw, (max-width: 991px) 95vw, 27vw"
                    src="https://savannahr.com/blog/content/images/size/w960/2024/07/Screenshot-2024-07-09-at-10.26.10-PM--1-.png"
                    alt="The Role of Social-Media in Your Job Search"
                    loading="lazy"
                  /> */}
                  {/* <img
                    src="https://cdn.prod.website-files.com/661d4839d9db6424c7cc0c1a/661f805abe29f59f7a48ac31_blog-image-2.jpg"
                    loading="lazy"
                    width="Auto"
                    alt=""
                    sizes="(max-width: 479px) 90vw, (max-width: 767px) 94vw, (max-width: 991px) 95vw, 27vw"
                    srcSet="https://cdn.prod.website-files.com/661d4839d9db6424c7cc0c1a/661f805abe29f59f7a48ac31_blog-image-2-p-500.jpg 500w, https://cdn.prod.website-files.com/661d4839d9db6424c7cc0c1a/661f805abe29f59f7a48ac31_blog-image-2-p-800.jpg 800w, https://cdn.prod.website-files.com/661d4839d9db6424c7cc0c1a/661f805abe29f59f7a48ac31_blog-image-2-p-1080.jpg 1080w, https://cdn.prod.website-files.com/661d4839d9db6424c7cc0c1a/661f805abe29f59f7a48ac31_blog-image-2.jpg 1568w"
                    className="margin-bottom margin-small"
                  /> */}
                </a>
                <div className="text-tiny-all-caps text-color-dark margin-bottom margin-xsmall">
                  Hiring Manager
                </div>
                <a
                  href="/post/securing-your-financial-future-through-retirement"
                  className="heading-link w-inline-block"
                >
                  <h3
                    aria-label="blog post link"
                    className="heading-style-h5 darkest-hover"
                  >
                    Questions to Ask Hiring Managers During a Job Interview
                  </h3>
                </a>
                <div className="author-line margin-top margin-xxsmall">
                  <img
                    src="https://media.licdn.com/dms/image/D5635AQH8OLTqTX3wuA/profile-framedphoto-shrink_200_200/0/1721631829515?e=1723618800&v=beta&t=YQwASN4FjP2KD4W5G4ebDWcrCNRRgaf_-6fS6I9nGI0"
                    loading="lazy"
                    alt=""
                    className="blog-author-small inline-block"
                  />
                  <div className="text-color-darker margin-left margin-xxsmall">
                    Swati Sinha
                  </div>
                </div>
              </div>
              <div
                role="listitem"
                className="collection-item slide-to-top-0-1s w-dyn-item"
                style={{
                  transform:
                    "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                  transformStyle: "preserve-3d",
                  opacity: 1,
                }}
              >
                <a
                  href="/post/the-smart-way-to-insure-your-financial-assets"
                  className="thumbnail-link-image w-inline-block"
                >
                  <img
                    className="margin-bottom margin-small"
                    style={{
                      objectFit: "cover",
                      height: "250px",
                    }}
                    width="auto"
                    srcSet="https://savannahr.com/blog/content/images/size/w400/2024/05/relocating-job.jpg 400w,
                            https://savannahr.com/blog/content/images/size/w750/2024/05/relocating-job.jpg 750w,
                            https://savannahr.com/blog/content/images/size/w960/2024/05/relocating-job.jpg 960w,
                            https://savannahr.com/blog/content/images/size/w1140/2024/05/relocating-job.jpg 1140w,
                            https://savannahr.com/blog/content/images/size/w1920/2024/05/relocating-job.jpg 1920w"
                    sizes="(min-width: 960px) 960px, 92vw"
                    src="https://savannahr.com/blog/content/images/size/w960/2024/05/relocating-job.jpg"
                    alt="Relocating for a Job: 7 Tips for Making it Work"
                    loading="lazy"
                  ></img>
                  {/* <img
                    className="margin-bottom margin-small"
                    style={{
                      objectFit: "cover",
                      height: "220px",
                    }}
                    width="auto"
                    srcSet="https://savannahr.com/blog/content/images/size/w400/2024/07/12-Interview-Questions-to-Ask-Remote-Workers-690x340.jpg 400w,
                    https://savannahr.com/blog/content/images/size/w750/2024/07/12-Interview-Questions-to-Ask-Remote-Workers-690x340.jpg 750w,
                    https://savannahr.com/blog/content/images/size/w960/2024/07/12-Interview-Questions-to-Ask-Remote-Workers-690x340.jpg 960w,
                    https://savannahr.com/blog/content/images/size/w1140/2024/07/12-Interview-Questions-to-Ask-Remote-Workers-690x340.jpg 1140w,
                    https://savannahr.com/blog/content/images/size/w1920/2024/07/12-Interview-Questions-to-Ask-Remote-Workers-690x340.jpg 1920w"
                    sizes="(max-width: 479px) 90vw, (max-width: 767px) 94vw, (max-width: 991px) 95vw, 27vw"
                    src="https://savannahr.com/blog/content/images/size/w960/2024/07/12-Interview-Questions-to-Ask-Remote-Workers-690x340.jpg"
                    alt="How to Gracefully Resign from your Job"
                    loading="lazy"
                  ></img> */}

                  {/* <img
                    src="https://cdn.prod.website-files.com/661d4839d9db6424c7cc0c1a/661f800f31df14189e1e54d0_blog-image-9.jpg"
                    loading="lazy"
                    width="Auto"
                    alt=""
                    sizes="(max-width: 479px) 90vw, (max-width: 767px) 94vw, (max-width: 991px) 95vw, 27vw"
                    srcSet="https://cdn.prod.website-files.com/661d4839d9db6424c7cc0c1a/661f800f31df14189e1e54d0_blog-image-9-p-500.jpg 500w, https://cdn.prod.website-files.com/661d4839d9db6424c7cc0c1a/661f800f31df14189e1e54d0_blog-image-9-p-800.jpg 800w, https://cdn.prod.website-files.com/661d4839d9db6424c7cc0c1a/661f800f31df14189e1e54d0_blog-image-9-p-1080.jpg 1080w, https://cdn.prod.website-files.com/661d4839d9db6424c7cc0c1a/661f800f31df14189e1e54d0_blog-image-9.jpg 1568w"
                    className="margin-bottom margin-small"
                  /> */}
                </a>
                <div className="text-tiny-all-caps text-color-dark margin-bottom margin-xsmall">
                  Job Interview
                </div>
                <a
                  href="/post/the-smart-way-to-insure-your-financial-assets"
                  className="heading-link w-inline-block"
                >
                  <h3
                    aria-label="blog post link"
                    className="heading-style-h5 darkest-hover"
                  >
                    Relocating for a Job: 7 Tips for Making it Work
                  </h3>
                </a>
                <div className="author-line margin-top margin-xxsmall">
                  <img
                    src="https://media.licdn.com/dms/image/D5635AQH8OLTqTX3wuA/profile-framedphoto-shrink_200_200/0/1721631829515?e=1723618800&v=beta&t=YQwASN4FjP2KD4W5G4ebDWcrCNRRgaf_-6fS6I9nGI0"
                    loading="lazy"
                    alt=""
                    className="blog-author-small inline-block"
                  />
                  <div className="text-color-darker margin-left margin-xxsmall">
                    Swati Sinha
                  </div>
                </div>
              </div>
              <div
                role="listitem"
                className="collection-item slide-to-top-0-1s w-dyn-item"
                style={{
                  transform:
                    "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                  transformStyle: "preserve-3d",
                  opacity: 1,
                }}
              >
                <a
                  href="/post/smart-estate-planning-for-financial-beginners"
                  className="thumbnail-link-image w-inline-block"
                >
                  <img
                    className="margin-bottom margin-small"
                    style={{
                      objectFit: "cover",
                      height: "250px",
                      width: "100%",
                    }}
                    srcSet="https://savannahr.com/blog/content/images/size/w400/2024/05/V1.png 400w,
                            https://savannahr.com/blog/content/images/size/w750/2024/05/V1.png 750w,
                            https://savannahr.com/blog/content/images/size/w960/2024/05/V1.png 960w,
                            https://savannahr.com/blog/content/images/size/w1140/2024/05/V1.png 1140w,
                            https://savannahr.com/blog/content/images/size/w1920/2024/05/V1.png 1920w"
                    sizes="(min-width: 960px) 960px, 92vw"
                    src="https://savannahr.com/blog/content/images/size/w960/2024/05/V1.png"
                    alt="10 Ways to Market Yourself to Future Employers"
                    loading="lazy"
                  ></img>
                  {/* <img
                    className="margin-bottom margin-small"
                    srcSet="https://savannahr.com/blog/content/images/size/w400/2024/05/istockphoto-1193929682-1024x1024.jpg 400w,
                            https://savannahr.com/blog/content/images/size/w750/2024/05/istockphoto-1193929682-1024x1024.jpg 750w,
                            https://savannahr.com/blog/content/images/size/w960/2024/05/istockphoto-1193929682-1024x1024.jpg 960w,
                            https://savannahr.com/blog/content/images/size/w1140/2024/05/istockphoto-1193929682-1024x1024.jpg 1140w,
                            https://savannahr.com/blog/content/images/size/w1920/2024/05/istockphoto-1193929682-1024x1024.jpg 1920w"
                    sizes="(max-width: 479px) 90vw, (max-width: 767px) 94vw, (max-width: 991px) 95vw, 27vw"
                    src="https://savannahr.com/blog/content/images/size/w960/2024/05/istockphoto-1193929682-1024x1024.jpg"
                    alt="How to Gracefully Resign from your Job"
                    style={{
                      objectFit: "cover",
                      height: "220px",
                    }}
                    loading="lazy"
                  ></img> */}
                  {/* <img
                    src="https://cdn.prod.website-files.com/661d4839d9db6424c7cc0c1a/661f7fb938b63e1acbc26976_blog-image-8.jpg"
                    loading="lazy"
                    width="Auto"
                    alt=""
                    sizes="(max-width: 479px) 90vw, (max-width: 767px) 94vw, (max-width: 991px) 95vw, 27vw"
                    srcSet="https://cdn.prod.website-files.com/661d4839d9db6424c7cc0c1a/661f7fb938b63e1acbc26976_blog-image-8-p-500.jpg 500w, https://cdn.prod.website-files.com/661d4839d9db6424c7cc0c1a/661f7fb938b63e1acbc26976_blog-image-8-p-800.jpg 800w, https://cdn.prod.website-files.com/661d4839d9db6424c7cc0c1a/661f7fb938b63e1acbc26976_blog-image-8-p-1080.jpg 1080w, https://cdn.prod.website-files.com/661d4839d9db6424c7cc0c1a/661f7fb938b63e1acbc26976_blog-image-8.jpg 1568w"
                    className="margin-bottom margin-small"
                  /> */}
                </a>
                <div className="text-tiny-all-caps text-color-dark margin-bottom margin-xsmall">
                  Recruiter Management
                </div>
                <a
                  href="/post/smart-estate-planning-for-financial-beginners"
                  className="heading-link w-inline-block"
                >
                  <h3
                    aria-label="blog post link"
                    className="heading-style-h5 darkest-hover"
                  >
                    10 Ways to Market Yourself to Future Employers
                  </h3>
                </a>
                <div className="author-line margin-top margin-xxsmall">
                  <img
                    src="https://media.licdn.com/dms/image/D5635AQH8OLTqTX3wuA/profile-framedphoto-shrink_200_200/0/1721631829515?e=1723618800&v=beta&t=YQwASN4FjP2KD4W5G4ebDWcrCNRRgaf_-6fS6I9nGI0"
                    loading="lazy"
                    alt=""
                    className="blog-author-small inline-block"
                  />
                  <div className="text-color-darker margin-left margin-xxsmall">
                    Swati Sinha
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default LatestBlogs
