import React, { useRef } from "react"
import {
  Grid,
  Container,
  Stack,
  Button,
  Box,
  IconButton,
  Chip,
  Typography,
  Card,
  CardContent,
  Divider,
} from "@mui/material"
import Slider from "react-slick"
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee"
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew"
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos"
import WorkOutlineOutlinedIcon from "@mui/icons-material/WorkOutlineOutlined"

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  arrows: false,
}

const JobCard = () => {
  return (
    <Card variant="outlined" sx={{ cursor: "pointer" }}>
      <CardContent sx={{ padding: 3 }}>
        <Stack direction="column" gap={3}>
          <Stack
            direction="column"
            gap={0.5}
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <Chip
              label="Full Time"
              color="primary"
              size="small"
              variant="outlined"
            />
            <Typography component="div">Software Test Engineer</Typography>
            <Typography variant="caption" component="span">
              5 days ago
            </Typography>
          </Stack>

          <Typography variant="body2" textAlign="justify">
            Savanna HR is a leading recruitment agency for fast growing
            organisations. We work for clients Pan India, Dubai, Middle east,
            Singapore, Vietnam, Australia
          </Typography>
          <Divider />
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
          >
            <Stack
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              gap={0.5}
            >
              <CurrencyRupeeIcon fontSize="small" />
              <Typography variant="body2">3-6 Yes</Typography>
            </Stack>

            <Stack
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              gap={0.5}
            >
              <WorkOutlineOutlinedIcon fontSize="small" />
              <Typography variant="body2">5-10 LPA</Typography>
            </Stack>
          </Box>
        </Stack>
      </CardContent>
    </Card>
  )
}

const FeaturedJobs = () => {
  const sliderRef = useRef(null)

  const nextSlide = () => {
    //@ts-ignore
    sliderRef?.current?.slickNext()
  }

  const prevSlide = () => {
    //@ts-ignore
    sliderRef?.current?.slickPrev()
  }

  return (
    <Grid
      container
      justifyContent="center"
      mb={2}
      mt={4}
      px={0}
      overflow="hidden"
    >
      <Box
        sx={{
          width: "100%",
          maxWidth: "1200px",
          margin: "0 auto",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "flex-end",
          paddingLeft: 0,
          paddingRight: 0,
        }}
      >
        <div style={{ width: "50%" }}>
          <div className="text-regular-all-caps margin-bottom margin-small slide-to-top-0-1s">
            Featured Jobs
          </div>
          <div className="custom-divider margin-bottom margin-small full-width-animation">
            <div className="divider-detail _24px-wide-animation"></div>
          </div>
          <h2 className="slide-to-top-0-2s">Explore Top Opportunities</h2>
        </div>
        <Stack
          direction="row"
          gap={1}
          justifyContent="flex-end"
          alignItems="center"
        >
          <IconButton
            color="primary"
            sx={{ border: "1px solid #599D15" }}
            onClick={prevSlide}
          >
            <ArrowBackIosNewIcon />
          </IconButton>
          <IconButton
            color="primary"
            sx={{ border: "1px solid #599D15" }}
            onClick={nextSlide}
          >
            <ArrowForwardIosIcon />
          </IconButton>
          <Button variant="outlined" size="large">
            View More
          </Button>
        </Stack>
      </Box>
      <Container
        maxWidth="xl"
        sx={{
          position: "relative",
          minHeight: "340px",
          overflowX: "visible",
          marginTop: 5,
          width: "100%",
          paddingLeft: "0px !important",
        }}
      >
        <Grid
          item
          xs={12}
          sx={{
            position: "absolute",
            overflow: "hidden",
            maxWidth: "1400px !important",
            width: "1400px",
          }}
        >
          <div>
            <Slider {...settings} ref={sliderRef}>
              {[1, 2, 3, 4, 5, 6, 7, 8].map(item => (
                <div key={`slide-${item}`}>
                  <JobCard />
                </div>
              ))}
            </Slider>
          </div>
        </Grid>
      </Container>
    </Grid>
  )
}

export default FeaturedJobs
