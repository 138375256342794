import React from "react"

import "./webflow.css"

import Stock from "../../resources/stock.jpg"
import Swati from "../../resources/swati.jpg"
import SearchIcon from "../../resources/search.svg"
import PeopleSerachIcon from "../../resources/people.svg"
import HandShakeIcon from "../../resources/hand-shake.svg"
import LegoImage from "../../resources/lego.png"

const ComprehensiveBlock = () => {
  return (
    <section className="padding-section-medium side-padding side-margin margin-top-fix">
      <div className="w-layout-blockcontainer default-width w-container">
        <div className="services-grid">
          <div id="w-node-b491b034-4662-c0bf-5d56-a17ad5cfee6c-d5cfee6b">
            <div className="text-regular-all-caps margin-bottom margin-small slide-to-top-0-1s">
              Comprehensive
            </div>
            <div className="custom-divider margin-bottom margin-small full-width-animation">
              <div className="divider-detail _24px-wide-animation"></div>
            </div>
            <h2 className="slide-to-top-0-2s">Recruit your best team</h2>
            <div className="services-image-wrapper">
              <img
                src={LegoImage}
                loading="lazy"
                sizes="(max-width: 479px) 90vw, (max-width: 767px) 94vw, (max-width: 991px) 46vw, 43vw"
                //   srcSet="
                //   https://cdn.prod.website-files.com/660329c18b6f6a95fa049791/660919328d8302eabf33258b_Dramatic-Abyss-p-500.jpg   500w,
                //   https://cdn.prod.website-files.com/660329c18b6f6a95fa049791/660919328d8302eabf33258b_Dramatic-Abyss-p-800.jpg   800w,
                //   https://cdn.prod.website-files.com/660329c18b6f6a95fa049791/660919328d8302eabf33258b_Dramatic-Abyss-p-1080.jpg 1080w,
                //   https://cdn.prod.website-files.com/660329c18b6f6a95fa049791/660919328d8302eabf33258b_Dramatic-Abyss.jpg        1632w
                // "
                alt="Abstract abyss"
                className="services-image margin-top margin-huge slide-to-top-0-2s"
              />
            </div>
          </div>
          <div
            id="w-node-b491b034-4662-c0bf-5d56-a17ad5cfee75-d5cfee6b"
            className="services-details-container"
          >
            <div className="second-services-grid">
              <div
                id="w-node-b491b034-4662-c0bf-5d56-a17ad5cfee77-d5cfee6b"
                className="services-image-wrapper"
              >
                <img
                  src={Stock}
                  loading="lazy"
                  width="840"
                  sizes="(max-width: 479px) 42vw, (max-width: 767px) 45vw, (max-width: 991px) 22vw, 19vw"
                  alt="Young businesswoman smiling"
                  //   srcSet="
                  //   https://cdn.prod.website-files.com/660329c18b6f6a95fa049791/6641fd2801fc37d321c477d9_member-gold-bg-6-p-500.jpg   500w,
                  //   https://cdn.prod.website-files.com/660329c18b6f6a95fa049791/6641fd2801fc37d321c477d9_member-gold-bg-6-p-800.jpg   800w,
                  //   https://cdn.prod.website-files.com/660329c18b6f6a95fa049791/6641fd2801fc37d321c477d9_member-gold-bg-6-p-1080.jpg 1080w,
                  //   https://cdn.prod.website-files.com/660329c18b6f6a95fa049791/6641fd2801fc37d321c477d9_member-gold-bg-6.jpg        1680w
                  // "
                  className="services-image second-small-image slide-to-top-0-2s"
                />
              </div>
              <div
                id="w-node-b491b034-4662-c0bf-5d56-a17ad5cfee79-d5cfee6b"
                className="services-image-wrapper"
              >
                <img
                  src={Swati}
                  loading="lazy"
                  width="840"
                  sizes="(max-width: 479px) 42vw, (max-width: 767px) 45vw, (max-width: 991px) 22vw, 19vw"
                  alt="Young asian businessman"
                  //   srcSet="
                  //   https://cdn.prod.website-files.com/660329c18b6f6a95fa049791/6641fc57c6266e0a820f2870_member-gold-bg-4-p-500.jpg   500w,
                  //   https://cdn.prod.website-files.com/660329c18b6f6a95fa049791/6641fc57c6266e0a820f2870_member-gold-bg-4-p-800.jpg   800w,
                  //   https://cdn.prod.website-files.com/660329c18b6f6a95fa049791/6641fc57c6266e0a820f2870_member-gold-bg-4-p-1080.jpg 1080w,
                  //   https://cdn.prod.website-files.com/660329c18b6f6a95fa049791/6641fc57c6266e0a820f2870_member-gold-bg-4.jpg        1680w
                  // "
                  className="services-image first-small-image slide-to-top-0-1s"
                />
              </div>
            </div>
            <p className="text-medium-normal text-color-darker margin-top margin-large slide-to-top-0-1s">
              Savanna HR is a specialist in leadership hiring. We conduct
              focussed outreach, rigorous screening, and find talent fit for the
              job. We are tech enabled, data driven, skilled recruiters who take
              a proactive approach to close your position.
              <br />
              <br />
              Our personalised, consultative approach offers you quality
              results, smooth delivery and saves time. Every time.
            </p>
            {/* <div style={{ marginTop: "10px" }}>
              <div
                id="w-node-b491b034-4662-c0bf-5d56-a17ad5cfee81-d5cfee6b"
                className="highlights-wrapper"
              >
                <div className="info-box-plus-label slide-to-top-0-1s">
                  <div className="icon-box bg-color-primary margin-right margin-xsmall">
                    <img
                      src={SearchIcon}
                      loading="lazy"
                      alt="Light lightbulb icon"
                      width={30}
                      height={30}
                    />
                  </div>
                  <div className="info-label">
                    <div className="text-medium-normal">Looking for a job?</div>
                  </div>
                  <a
                    href=""
                    className="button is-small dropdown-button darkest-to-brand w-button bg-color-primary"
                  >
                    Search Job
                  </a>
                </div>
                <div
                  id="w-node-b491b034-4662-c0bf-5d56-a17ad5cfee82-d5cfee6b"
                  className="info-box-plus-label slide-to-top-0-1s"
                >
                  <div className="icon-box bg-color-primary margin-right margin-xsmall">
                    <img
                      src={HandShakeIcon}
                      loading="lazy"
                      alt="Light lightbulb icon"
                      width={30}
                      height={30}
                    />
                  </div>
                  <div className="info-label">
                    <div className="text-medium-normal">Looking to hire?</div>
                  </div>
                  <a
                    href=""
                    className="button is-small dropdown-button darkest-to-brand w-button bg-color-primary"
                  >
                    Request a callback
                  </a>
                </div>
                <div
                  id="w-node-b491b034-4662-c0bf-5d56-a17ad5cfee87-d5cfee6b"
                  className="info-box-plus-label slide-to-top-0-2s"
                >
                  <div className="icon-box bg-color-primary margin-right margin-xsmall">
                    <img
                      src={PeopleSerachIcon}
                      loading="lazy"
                      alt="Pawn chess figure"
                      width={30}
                      height={30}
                    />
                  </div>
                  <div className="info-label">
                    <div className="text-medium-normal">Join Us</div>
                  </div>
                  <a
                    href=""
                    className="button is-small dropdown-button darkest-to-brand w-button bg-color-primary"
                  >
                    Work for us
                  </a>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </section>
  )
}

export default ComprehensiveBlock
