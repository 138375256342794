import React from "react"
import {
  Box,
  Grid,
  Container,
  Typography,
  Stack,
  TextField,
  Button,
  styled,
  Select,
  FormControl,
  MenuItem,
} from "@mui/material"

const CustomTextField = styled(TextField)({
  "& .MuiInputBase-input": {
    fontSize: "16px",
    paddingY: 2,
  },
  "& .MuiInputLabel-root": {
    fontSize: "16px",
  },
})

const ContactSection = () => {
  return (
    <Box
      bgcolor="#ECFDF5"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Container maxWidth="xl">
        <Grid container py={12}>
          <Grid item xs={12} md={6}>
            <Stack
              direction="column"
              gap={3}
              width="80%"
              justifyItems="flex-start"
            >
              <Typography variant="h2">Contact Us</Typography>
              <Typography variant="body2">
                We create limitless opportunities by connecting people to work
                in ways that enrich their lives, and connecting companies to the
                people who drive innovation and growth
              </Typography>
              <Typography variant="body2">
                Whether you’re a job seeker or a hiring professional, contact us
                today to get started.
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={12} md={6}>
            <Stack
              direction="column"
              gap={2.5}
              justifyContent="flex-start"
              alignItems="flex-start"
              width="100%"
            >
              <Typography variant="h3">Let's Talk!</Typography>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="flex-start"
                width="100%"
              >
                <Typography variant="caption" fontWeight="bold" mb={1}>
                  How can we help?*
                </Typography>
                <FormControl fullWidth>
                  <Select
                    value={10}
                    size="small"
                    inputProps={{
                      sx: {
                        fontSize: "16px",
                        paddingY: 0.6,
                      },
                    }}
                  >
                    <MenuItem value={10}>
                      Recruiting and hiring at my company
                    </MenuItem>
                    <MenuItem value={20}>Finding a new job or career</MenuItem>
                  </Select>
                </FormControl>
                {/* <CustomTextField
                  value="Recruiting and hiring at my company"
                  size="small"
                  fullWidth
                /> */}
              </Box>
              <CustomTextField label="First name*" size="small" fullWidth />
              <CustomTextField label="Last name*" size="small" fullWidth />
              <CustomTextField label="Company email*" size="small" fullWidth />
              <CustomTextField label="Phone" size="small" fullWidth />
              <Button variant="contained">SUBMIT</Button>
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}

export default ContactSection
