import React from "react"
import { Box, Typography } from "@mui/material"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

import Title from "./Title"

//@ts-ignore
import Appolo from "../resources/clients/appolo-munich.png"
//@ts-ignore
import Britannica from "../resources/clients/britannica.png"
//@ts-ignore
import Cuemath from "../resources/clients/cuemath.png"
//@ts-ignore
import Extramark from "../resources/clients/extramark.png"
//@ts-ignore
import pearson from "../resources/clients/pearson.png"
//@ts-ignore
import peopleCombine from "../resources/clients/people-combine.png"
//@ts-ignore
import SChand from "../resources/clients/s-chand.png"
//@ts-ignore
import splashMath from "../resources/clients/splash-math.png"
//@ts-ignore
import xseed from "../resources/clients/xseed.png"

import "./client-section.css"

// const options = {
//   loop: true,
//   margin: 10,
//   nav: true,
//   autoplay: true,
//   autoplayTimeout: 3000,
//   autoplayHoverPause: true,
//   responsive: {
//     0: {
//       items: 1,
//     },
//     600: {
//       items: 3,
//     },
//     1000: {
//       items: 5,
//     },
//   },
// }

const settings = {
  infinite: true,
  speed: 9000, // Set a large speed for smooth transition
  slidesToShow: 6, // Number of slides to show at once
  slidesToScroll: 1, // Number of slides to scroll at once
  autoplay: true,
  autoplaySpeed: 0, // Set to 0 for continuous autoplay
  cssEase: "linear", // Linear easing for smooth transition
  arrows: false, // Disable arrows
  pauseOnHover: false, // Don't pause on hover
  rtl: true,
}

const settings1 = {
  infinite: true,
  speed: 9000, // Set a large speed for smooth transition
  slidesToShow: 4, // Number of slides to show at once
  slidesToScroll: 1, // Number of slides to scroll at once
  autoplay: true,
  autoplaySpeed: 0, // Set to 0 for continuous autoplay
  cssEase: "linear", // Linear easing for smooth transition
  arrows: false, // Disable arrows
  pauseOnHover: false, // Don't pause on hover
  rtl: false,
}

const ClientSection = (props: any) => {
  return (
    <>
      <Typography
        variant="body2"
        fontWeight="bold"
        align="center"
        color="primary"
        sx={{
          marginTop: 6,
          marginBottom: 2,
          fontSize: "25px",
        }}
      >
        Trusted by 100+ Companies
      </Typography>
      <Box display="flex" width="100%" py={2} mb={6} overflow={"hidden"}>
        <Slider {...settings} className="slick-wrapper">
          <div className="item">
            <img src={Appolo} alt="Appllo-munic" width="200px" height="85px" />
          </div>
          <div className="item">
            <img
              src={Britannica}
              alt="Britannica"
              width="200px"
              height="85px"
            />
          </div>
          <div className="item">
            <img src={Cuemath} alt="Cuemath" width="200px" height="85px" />
          </div>
          <div className="item">
            <img src={Extramark} alt="Extramark" width="200px" height="85px" />
          </div>
          <div className="item">
            <img src={pearson} alt="pearson" width="200px" height="85px" />
          </div>
          <div className="item">
            <img
              src={peopleCombine}
              alt="People Combine"
              width="200px"
              height="85px"
            />
          </div>
          <div className="item">
            <img src={SChand} alt="S Chand" width="200px" height="85px" />
          </div>
          <div className="item">
            <img
              src={splashMath}
              alt="Splash Match"
              width="200px"
              height="85px"
            />
          </div>
          <div className="item">
            <img src={xseed} alt="Xseed" width="200px" height="85px" />
          </div>
        </Slider>
      </Box>
      {/* <Box display="flex" width="100%" py={2}>
        <Slider {...settings1} className="slick-wrapper">
          <div className="item">
            <img src={Appolo} alt="Appllo-munic" width="200px" height="85px" />
          </div>
          <div className="item">
            <img
              src={Britannica}
              alt="Britannica"
              width="200px"
              height="85px"
            />
          </div>
          <div className="item">
            <img src={Cuemath} alt="Cuemath" width="200px" height="85px" />
          </div>
          <div className="item">
            <img src={Extramark} alt="Extramark" width="200px" height="85px" />
          </div>
          <div className="item">
            <img src={pearson} alt="pearson" width="200px" height="85px" />
          </div>
          <div className="item">
            <img
              src={peopleCombine}
              alt="People Combine"
              width="200px"
              height="85px"
            />
          </div>
          <div className="item">
            <img src={SChand} alt="S Chand" width="200px" height="85px" />
          </div>
          <div className="item">
            <img
              src={splashMath}
              alt="Splash Match"
              width="200px"
              height="85px"
            />
          </div>
          <div className="item">
            <img src={xseed} alt="Xseed" width="200px" height="85px" />
          </div>
        </Slider>
      </Box> */}
    </>
  )
}

export default ClientSection
