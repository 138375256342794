import React from "react"
import { graphql } from "gatsby"
import { Box } from "@mui/material"

import Head from "../components/Banner"
import Footer from "../components/Footer"
import ClientSection from "../components/ClientSection"
//@ts-ignore
import {
  ComprehensiveBlock,
  TailoredFinancialSolutions,
  SuccessStories,
  // ClientSection,
  LatestBlogs,
  QuickActions,
  BlogSlider,
  FeaturedJobs,
  ContactSection,
} from "../components/home-components"

const IndexPage = (props: any) => {
  const data = props.data
  return (
    <Box display="flex" flexDirection="column" flexWrap="wrap">
      <Head data={data} />
      <ClientSection client={data.allFile} />
      {/* <ClientSection /> */}
      <ComprehensiveBlock />
      <QuickActions />
      <FeaturedJobs />
      <TailoredFinancialSolutions />
      <BlogSlider />
      <LatestBlogs />
      <SuccessStories />
      <ContactSection />
      <Footer />
    </Box>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query {
    allWordpressPost(limit: 3) {
      edges {
        node {
          title
          excerpt
          slug
          date(fromNow: true)
          author {
            id
            slug
            name
            url
            description
          }
        }
      }
    }
    file(relativePath: { eq: "icons/bg.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 1080) {
          # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`
