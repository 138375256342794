import React from "react"

import "./webflow.css"

import AnustupNayak from "../../resources/peoples/anusput-nayak.jpeg"
import SarikaSharma from "../../resources/peoples/sarika.jpeg"
import AbhinavSehgal from "../../resources/peoples/abhinav-sehgal.jpg"
import FemalePlaceholder from "../../resources/peoples/female-placeholder.jpg"
import HeartHandshakeIcon from "../../resources/heart-handshake.svg"

const SuccessStories = () => {
  return (
    <section className="padding-section-medium side-padding margin-horizontal margin-large">
      <div className="w-layout-blockcontainer default-width w-container">
        <div className="one-third-grid testimonials-grid">
          <div
            id="w-node-afd33bdc-43dd-d636-5ba9-0d63ca98d12e-fa0497a1"
            className="slide-to-top-0-1s"
          >
            <img
              src={HeartHandshakeIcon}
              width="72"
              alt="Modern abstract illustration"
              className="margin-bottom margin-medium"
            />
            <h2
              id="w-node-_8c51ba29-6692-0ab6-fa31-47ebbd276ffe-fa0497a1"
              className="margin-bottom margin-small"
            >
              Real Stories, <br />
              Real Success
            </h2>
            <div className="custom-divider margin-bottom margin-small full-width-animation">
              <div className="divider-detail _24px-wide-animation"></div>
            </div>
            <p className="text-medium-normal text-weight-medium margin-bottom margin-small">
              Our clients are at the heart of everything we do.
            </p>
            <p className="text-medium-normal text-color-darker">
              Here&#x27;s what they have to say about the difference our
              financial services have made in their lives and businesses.
            </p>
          </div>
          <div
            id="w-node-_4e1e7be9-c7c5-217f-3c64-797dc9ef935a-fa0497a1"
            className="two-column-grid-1-5rem our-team"
          >
            <div id="w-node-d4f46bad-3ad0-312e-27f3-19b390f6865b-fa0497a1">
              <div className="testimonial-container padding-small slide-to-top-0-3s">
                <p className="text-weight-normal text-medium-normal">
                  &quot;Savanna HR is an excellent Recruitment firm for EdTech
                  Hiring. I was guided throughout and helped with an excellent
                  opportunity in Education Industry. Team is very helpful with
                  good network.&quot;
                </p>
                <div className="testimonial-details margin-top margin-small">
                  <img
                    src={FemalePlaceholder}
                    loading="lazy"
                    width="840"
                    id="w-node-_13ffa2f2-9d43-b81d-d3ee-17f2257b8c84-fa0497a1"
                    alt="Young Asian businessman on a gold background"
                    //     srcSet="
                    //     https://cdn.prod.website-files.com/660329c18b6f6a95fa049791/6641fe4ab8baaeb6413c2bbc_member-gold-bg-7-p-500.jpg   500w,
                    //     https://cdn.prod.website-files.com/660329c18b6f6a95fa049791/6641fe4ab8baaeb6413c2bbc_member-gold-bg-7-p-800.jpg   800w,
                    //     https://cdn.prod.website-files.com/660329c18b6f6a95fa049791/6641fe4ab8baaeb6413c2bbc_member-gold-bg-7-p-1080.jpg 1080w,
                    //     https://cdn.prod.website-files.com/660329c18b6f6a95fa049791/6641fe4ab8baaeb6413c2bbc_member-gold-bg-7.jpg        1680w
                    //   "
                    sizes="56px"
                    className="image-2"
                  />
                  <div
                    id="w-node-_13ffa2f2-9d43-b81d-d3ee-17f2257b8c85-fa0497a1"
                    className="inline-flex"
                  >
                    <div>
                      <div className="testimonial-author text-weight-medium">
                        Monica Singh
                      </div>
                      <div className="text-size-small text-color-darker"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="testimonial-container padding-small margin-top margin-small slide-to-top-0-3s">
                <p className="text-weight-normal text-medium-normal">
                  &quot;Swati and her team are very professional and are talent
                  specialists. They have helped us fill critical positions on
                  time. I highly recommend their services and wish them
                  luck.&quot;
                </p>
                <div className="testimonial-details margin-top margin-small">
                  <img
                    src={SarikaSharma}
                    loading="lazy"
                    width="840"
                    id="w-node-_0c762397-d05f-4225-1bb6-7242e4c9ed0b-fa0497a1"
                    alt="Middle-aged asian businesswoman on a gold background"
                    //     srcSet="
                    //     https://cdn.prod.website-files.com/660329c18b6f6a95fa049791/6642027201fc37d321c9484c_member-gold-bg-2-p-500.jpg   500w,
                    //     https://cdn.prod.website-files.com/660329c18b6f6a95fa049791/6642027201fc37d321c9484c_member-gold-bg-2-p-800.jpg   800w,
                    //     https://cdn.prod.website-files.com/660329c18b6f6a95fa049791/6642027201fc37d321c9484c_member-gold-bg-2-p-1080.jpg 1080w,
                    //     https://cdn.prod.website-files.com/660329c18b6f6a95fa049791/6642027201fc37d321c9484c_member-gold-bg-2.jpg        1680w
                    //   "
                    sizes="56px"
                    className="image-2"
                  />
                  <div
                    id="w-node-_0c762397-d05f-4225-1bb6-7242e4c9ed0c-fa0497a1"
                    className="inline-flex"
                  >
                    <div>
                      <div className="testimonial-author text-weight-medium">
                        Sarika Sharma
                      </div>
                      <div className="text-size-small text-color-darker">
                        Senior Manager - Talent Aquisition LEAD School
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="testimonial-container padding-small margin-top margin-small slide-to-top-0-3s">
                <p className="text-weight-normal text-medium-normal">
                  &quot;For top-notch recruitment services, Savanna HR is the
                  way to go. They deliver spot-on solutions with a professional
                  approach, and their knowledgeable team makes all the
                  difference.&quot;
                </p>
                <div className="testimonial-details margin-top margin-small">
                  <img
                    src="https://cdn.prod.website-files.com/660329c18b6f6a95fa049791/6641fc57c6266e0a820f2870_member-gold-bg-4.jpg"
                    loading="lazy"
                    width="840"
                    id="w-node-_1cba4977-22b8-5c31-0b49-f3000734d4df-fa0497a1"
                    alt="Young asian businessman"
                    srcSet="
                    https://cdn.prod.website-files.com/660329c18b6f6a95fa049791/6641fc57c6266e0a820f2870_member-gold-bg-4-p-500.jpg   500w,
                    https://cdn.prod.website-files.com/660329c18b6f6a95fa049791/6641fc57c6266e0a820f2870_member-gold-bg-4-p-800.jpg   800w,
                    https://cdn.prod.website-files.com/660329c18b6f6a95fa049791/6641fc57c6266e0a820f2870_member-gold-bg-4-p-1080.jpg 1080w,
                    https://cdn.prod.website-files.com/660329c18b6f6a95fa049791/6641fc57c6266e0a820f2870_member-gold-bg-4.jpg        1680w
                  "
                    sizes="56px"
                    className="image-2"
                  />
                  <div
                    id="w-node-_1cba4977-22b8-5c31-0b49-f3000734d4e0-fa0497a1"
                    className="inline-flex"
                  >
                    <div>
                      <div className="testimonial-author text-weight-medium">
                        Abhinav Sehgal
                      </div>
                      <div className="text-size-small text-color-darker">
                        Group Head HR, AAFT
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
            <div id="w-node-a1d0add6-47ba-4dd4-e589-a536b9127cef-fa0497a1">
              <div className="testimonial-container padding-small slide-to-top-02s">
                <p className="text-weight-normal text-medium-normal">
                  &quot;I have worked very closely with the Savanna HR team and
                  they have helped us to close many niche hires. I find the team
                  committed to quality service delivery and open to
                  feedback.&quot;
                </p>
                <div className="testimonial-details margin-top margin-small">
                  <img
                    src={AnustupNayak}
                    loading="lazy"
                    width="840"
                    id="w-node-a1d0add6-47ba-4dd4-e589-a536b9127cf4-fa0497a1"
                    alt="Young asian businessman"
                    //     srcSet="
                    //     https://cdn.prod.website-files.com/660329c18b6f6a95fa049791/66420232c57c1826380ccda6_member-gold-bg-5-p-500.jpg   500w,
                    //     https://cdn.prod.website-files.com/660329c18b6f6a95fa049791/66420232c57c1826380ccda6_member-gold-bg-5-p-800.jpg   800w,
                    //     https://cdn.prod.website-files.com/660329c18b6f6a95fa049791/66420232c57c1826380ccda6_member-gold-bg-5-p-1080.jpg 1080w,
                    //     https://cdn.prod.website-files.com/660329c18b6f6a95fa049791/66420232c57c1826380ccda6_member-gold-bg-5.jpg        1680w
                    //   "
                    sizes="56px"
                    className="image-2"
                  />
                  <div
                    id="w-node-a1d0add6-47ba-4dd4-e589-a536b9127cf5-fa0497a1"
                    className="inline-flex"
                  >
                    <div>
                      <div className="testimonial-author text-weight-medium">
                        Anustup Nayak
                      </div>
                      <div className="text-size-small text-color-darker">
                        VP, XSEED Education
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="testimonial-container padding-small margin-top margin-small slide-to-top-0-2s">
                <p className="text-weight-normal text-medium-normal">
                  &quot;Savanna HR have always shown sincere interest in the
                  deliverables for skills across levels. Their participation in
                  requirement understanding commendable. I have always relied on
                  for their round the clock availability.&quot;
                </p>
                <div className="testimonial-details margin-top margin-small">
                  <img
                    src={FemalePlaceholder}
                    loading="lazy"
                    width="840"
                    id="w-node-a1d0add6-47ba-4dd4-e589-a536b9127cff-fa0497a1"
                    alt="Middle-aged caucasian businessman on a gold background"
                    //     srcSet="
                    //     https://cdn.prod.website-files.com/660329c18b6f6a95fa049791/6642024fdb9cbfbc2c8f6686_member-gold-bg-1-p-500.jpg   500w,
                    //     https://cdn.prod.website-files.com/660329c18b6f6a95fa049791/6642024fdb9cbfbc2c8f6686_member-gold-bg-1-p-800.jpg   800w,
                    //     https://cdn.prod.website-files.com/660329c18b6f6a95fa049791/6642024fdb9cbfbc2c8f6686_member-gold-bg-1-p-1080.jpg 1080w,
                    //     https://cdn.prod.website-files.com/660329c18b6f6a95fa049791/6642024fdb9cbfbc2c8f6686_member-gold-bg-1.jpg        1680w
                    //   "
                    sizes="56px"
                    className="image-2"
                  />
                  <div
                    id="w-node-a1d0add6-47ba-4dd4-e589-a536b9127d00-fa0497a1"
                    className="inline-flex"
                  >
                    <div>
                      <div className="testimonial-author text-weight-medium">
                        Tanvi Jain
                      </div>
                      <div className="text-size-small text-color-darker">
                        HR, Pearson Education
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="testimonial-container padding-small margin-top margin-small slide-to-top-0-2s">
                <p className="text-weight-normal text-medium-normal">
                  &quot;For top-notch recruitment services, Savanna HR is the
                  way to go. They deliver spot-on solutions with a professional
                  approach, and their knowledgeable team makes all the
                  difference.&quot;
                </p>
                <div className="testimonial-details margin-top margin-small">
                  <img
                    src={AbhinavSehgal}
                    width="840"
                    id="w-node-_161818d7-a020-34e1-43bb-212b8fa58af3-fa0497a1"
                    alt="Young caucasian businessman on a gold background"
                    //     srcSet="
                    //     https://cdn.prod.website-files.com/660329c18b6f6a95fa049791/6642026be8db6679e58f2e1b_member-gold-bg-8-p-500.jpg   500w,
                    //     https://cdn.prod.website-files.com/660329c18b6f6a95fa049791/6642026be8db6679e58f2e1b_member-gold-bg-8-p-800.jpg   800w,
                    //     https://cdn.prod.website-files.com/660329c18b6f6a95fa049791/6642026be8db6679e58f2e1b_member-gold-bg-8-p-1080.jpg 1080w,
                    //     https://cdn.prod.website-files.com/660329c18b6f6a95fa049791/6642026be8db6679e58f2e1b_member-gold-bg-8.jpg        1680w
                    //   "
                    sizes="56px"
                    className="image-2"
                  />
                  <div
                    id="w-node-_161818d7-a020-34e1-43bb-212b8fa58af4-fa0497a1"
                    className="inline-flex"
                  >
                    <div>
                      <div className="testimonial-author text-weight-medium">
                        Abhinav Sehgal
                      </div>
                      <div className="text-size-small text-color-darker">
                        Group Head HR, AAFT
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default SuccessStories
