import React from "react"

//@ts-ignore
import MailSVGIcon from "../../resources/mail-star.svg"
//@ts-ignore
import ChartInfographicSVGIcon from "../../resources/chart-infographic.svg"
//@ts-ignore
import AnalyzeSVGIcon from "../../resources/analyze.svg"
//@ts-ignore
import GlobeSVGIcon from "../../resources/globe.svg"
//@ts-ignore
import StreamSVGIcon from "../../resources/steam.svg"
//@ts-ignore
import AISVGIcon from "../../resources/ai.svg"
//@ts-ignore
import DevicePadSVGIcon from "../../resources/device-ipad.svg"
//@ts-ignore
import InputSearchSVGIcon from "../../resources/input-search.svg"
import "./webflow.css"

const TailoredFinancialSolutions = () => {
  return (
    <section className="padding-section-medium side-padding margin-horizontal margin-large">
      <div className="w-layout-blockcontainer default-width w-container">
        <div
          id="w-node-_2be2d147-95cb-2c6c-4545-611ecfd01b29-fa0497a1"
          className="w-layout-grid service-title"
        >
          <div id="w-node-_4eabdeae-ef98-3ab8-0ddb-26171b368988-fa0497a1">
            <h2 className="margin-bottom margin-huge slide-to-top-0-1s">
              Savanna HR Advantage
            </h2>
          </div>
        </div>
        <div className="four-column-grid four-column-service">
          <div
            id="w-node-_6c493317-9626-bbca-e7de-b99c184678c6-184678c5"
            className="slide-to-top-0-1s"
          >
            <img
              src={StreamSVGIcon}
              loading="lazy"
              width="72"
              alt="Modern abstract illustration"
              className="margin-bottom margin-large"
            />
            <h5 className="text-color-darkest margin-bottom margin-small">
              Consultative approach
            </h5>
            <div className="custom-divider margin-bottom margin-small full-width-animation">
              <div className="divider-detail _24px-wide-animation"></div>
            </div>
            <p className="text-color-darker margin-bottom margin-small">
              We understand client needs, research the market, and deliver
              candidates that match expectations.
            </p>
            <a href="/services" className="link-block-wrapper w-inline-block">
              <div className="link-block-text">Learn More</div>
              <div className="svg-icon-24px opacity-035 margin-left margin-xxsmall w-embed">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5 12H19"
                    stroke="currentcolor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M13 18L19 12"
                    stroke="currentcolor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M13 6L19 12"
                    stroke="currentcolor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            </a>
          </div>
          <div
            id="w-node-_6c493317-9626-bbca-e7de-b99c184678d2-184678c5"
            className="slide-to-top-0-2s"
          >
            <img
              src={InputSearchSVGIcon}
              loading="lazy"
              width="72"
              alt="Modern abstract illustration"
              className="margin-bottom margin-large"
            />
            <h5 className="text-color-darkest margin-bottom margin-small">
              Thorough research
            </h5>
            <div className="custom-divider margin-bottom margin-small full-width-animation">
              <div className="divider-detail _24px-wide-animation"></div>
            </div>
            <p className="text-color-darker margin-bottom margin-small">
              We research your company, industry peers, and market expectations
              to craft a job opening profile.
            </p>
            <a href="/services" className="link-block-wrapper w-inline-block">
              <div className="link-block-text">Learn More</div>
              <div className="svg-icon-24px opacity-035 margin-left margin-xxsmall w-embed">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5 12H19"
                    stroke="currentcolor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M13 18L19 12"
                    stroke="currentcolor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M13 6L19 12"
                    stroke="currentcolor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            </a>
          </div>
          <div
            id="w-node-e4a32261-dc7f-88e1-0772-9f4d079fc345-184678c5"
            className="slide-to-top-0-3s"
          >
            <img
              src={MailSVGIcon}
              loading="lazy"
              width="72"
              alt="Modern abstract illustration"
              className="margin-bottom margin-large"
            />
            <h5 className="text-color-darkest margin-bottom margin-small">
              Focussed Outreach
            </h5>
            <div className="custom-divider margin-bottom margin-small full-width-animation">
              <div className="divider-detail _24px-wide-animation"></div>
            </div>
            <p className="text-color-darker margin-bottom margin-small">
              We manage a deep talent pool through our candidate database, job
              board, and social media.
            </p>
            <a href="/services" className="link-block-wrapper w-inline-block">
              <div className="link-block-text">Learn More</div>
              <div className="svg-icon-24px opacity-035 margin-left margin-xxsmall w-embed">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5 12H19"
                    stroke="currentcolor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M13 18L19 12"
                    stroke="currentcolor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M13 6L19 12"
                    stroke="currentcolor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            </a>
          </div>
          <div className="slide-to-top-0-4s">
            <img
              src={AnalyzeSVGIcon}
              loading="lazy"
              width="72"
              alt="Modern abstract illustration"
              className="margin-bottom margin-large"
            />
            <h5 className="text-color-darkest margin-bottom margin-small">
              Rigorous screening
            </h5>
            <div className="custom-divider margin-bottom margin-small full-width-animation">
              <div className="divider-detail _24px-wide-animation"></div>
            </div>
            <p className="text-color-darker margin-bottom margin-small">
              We evaluate profiles, align with your preferences, and use our
              expertise to screen candidates.
            </p>
            <a href="/services" className="link-block-wrapper w-inline-block">
              <div className="link-block-text">Learn More</div>
              <div className="svg-icon-24px opacity-035 margin-left margin-xxsmall w-embed">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5 12H19"
                    stroke="currentcolor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M13 18L19 12"
                    stroke="currentcolor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M13 6L19 12"
                    stroke="currentcolor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            </a>
          </div>
        </div>
        <div
          className="four-column-grid four-column-service"
          style={{ marginTop: "4em" }}
        >
          <div
            id="w-node-_6c493317-9626-bbca-e7de-b99c184678c6-184678c5"
            className="slide-to-top-0-1s"
          >
            <img
              src={DevicePadSVGIcon}
              loading="lazy"
              width="72"
              alt="Modern abstract illustration"
              className="margin-bottom margin-large"
            />
            <h5 className="text-color-darkest margin-bottom margin-small">
              Digitally savvy
            </h5>
            <div className="custom-divider margin-bottom margin-small full-width-animation">
              <div className="divider-detail _24px-wide-animation"></div>
            </div>
            <p className="text-color-darker margin-bottom margin-small">
              Our digital team develops job boards, ATS, and CRM systems to
              centralize all data.
            </p>
            <a href="/services" className="link-block-wrapper w-inline-block">
              <div className="link-block-text">Learn More</div>
              <div className="svg-icon-24px opacity-035 margin-left margin-xxsmall w-embed">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5 12H19"
                    stroke="currentcolor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M13 18L19 12"
                    stroke="currentcolor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M13 6L19 12"
                    stroke="currentcolor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            </a>
          </div>
          <div
            id="w-node-_6c493317-9626-bbca-e7de-b99c184678d2-184678c5"
            className="slide-to-top-0-2s"
          >
            <img
              src={AISVGIcon}
              loading="lazy"
              width="72"
              alt="Modern abstract illustration"
              className="margin-bottom margin-large"
            />
            <h5 className="text-color-darkest margin-bottom margin-small">
              AI enabled
            </h5>
            <div className="custom-divider margin-bottom margin-small full-width-animation">
              <div className="divider-detail _24px-wide-animation"></div>
            </div>
            <p className="text-color-darker margin-bottom margin-small">
              We use AI to search and rank CVs efficiently, while our team
              continuously develops new recruitment tools.
            </p>
            <a href="/services" className="link-block-wrapper w-inline-block">
              <div className="link-block-text">Learn More</div>
              <div className="svg-icon-24px opacity-035 margin-left margin-xxsmall w-embed">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5 12H19"
                    stroke="currentcolor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M13 18L19 12"
                    stroke="currentcolor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M13 6L19 12"
                    stroke="currentcolor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            </a>
          </div>
          <div
            id="w-node-_6c493317-9626-bbca-e7de-b99c184678d2-184678c5"
            className="slide-to-top-0-2s"
          >
            <img
              src={GlobeSVGIcon}
              loading="lazy"
              width="72"
              alt="Modern abstract illustration"
              className="margin-bottom margin-large"
            />
            <h5 className="text-color-darkest margin-bottom margin-small">
              Global reach
            </h5>
            <div className="custom-divider margin-bottom margin-small full-width-animation">
              <div className="divider-detail _24px-wide-animation"></div>
            </div>
            <p className="text-color-darker margin-bottom margin-small">
              We have successfully sourced and placed candidates from the APAC
              region, Europe, and the US.
            </p>
            <a href="/services" className="link-block-wrapper w-inline-block">
              <div className="link-block-text">Learn More</div>
              <div className="svg-icon-24px opacity-035 margin-left margin-xxsmall w-embed">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5 12H19"
                    stroke="currentcolor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M13 18L19 12"
                    stroke="currentcolor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M13 6L19 12"
                    stroke="currentcolor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            </a>
          </div>
          <div
            id="w-node-_6c493317-9626-bbca-e7de-b99c184678d2-184678c5"
            className="slide-to-top-0-2s"
          >
            <img
              src={ChartInfographicSVGIcon}
              loading="lazy"
              width="72"
              alt="Modern abstract illustration"
              className="margin-bottom margin-large"
            />
            <h5 className="text-color-darkest margin-bottom margin-small">
              Data driven culture
            </h5>
            <div className="custom-divider margin-bottom margin-small full-width-animation">
              <div className="divider-detail _24px-wide-animation"></div>
            </div>
            <p className="text-color-darker margin-bottom margin-small">
              Our data-driven culture streamlines the hiring process and ensures
              a smooth pipeline.
            </p>
            <a href="/services" className="link-block-wrapper w-inline-block">
              <div className="link-block-text">Learn More</div>
              <div className="svg-icon-24px opacity-035 margin-left margin-xxsmall w-embed">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5 12H19"
                    stroke="currentcolor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M13 18L19 12"
                    stroke="currentcolor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M13 6L19 12"
                    stroke="currentcolor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            </a>
          </div>
        </div>
      </div>
    </section>
  )
}

export default TailoredFinancialSolutions
